import { Form, Select } from "antd";
import React from "react";
import MaskedDatePicker from "../../../../components/masked-date-picker/masked-date-picker";
import { IBannerEdit } from "../../../../models/banner.model";

import "./SelectBanner.scss"
import moment from "moment-timezone";

const { Option } = Select;

interface IPropsSelect {
    isEdit?: boolean,
    currentEdit: IBannerEdit,
    setCurrentEdit: (banner: IBannerEdit) => void,
    form: any,
};

const options = [
   { key: "Exibição única", value: 'SINGLE_VIEW' },
   { key: "Até cancelar", value: 'UNTIL_USER_DISABLE' },
]

const DataPicker = ({onChangeDate, expirationDate}: any) => {
    return (
        <div className="data-Picker">
        <MaskedDatePicker
            forcePeriodOfDay='END'
            onChange={onChangeDate}
            currentValue={(expirationDate) ? expirationDate : null}
            stringType={"expirationDate"}
            classText="enrollment-date"
            placeholder="DD/MM/AAAA"
        />
    </div>
    )
}

const SelectBanner = ({currentEdit, setCurrentEdit, form}: IPropsSelect) => {
    const {getFieldDecorator} = form;

    function onChangeViewType(e: string) {
        let item = {...currentEdit, viewType: e};
        setCurrentEdit(item as IBannerEdit);
    };

    function onChangeDate(key: string, value: string) {
        let item = {...currentEdit};
        item = {...item, [key]: value};
        setCurrentEdit(item as IBannerEdit);
    };

    return (
        <div className="select-banner">
            <div className="select-view-type">
                <Form.Item label='Modo de exibição'>
                    {getFieldDecorator('viewType', {
                        rules: [
                            { required: true, message: 'Campo obrigatório' },
                        ],
                    })(
                        <Select
                            className="input-filter"
                            mode="default"
                            placeholder="Tipo de exibição"
                            maxTagCount={1}
                            onChange={(e: string) => onChangeViewType(e as string)}
                        >
                            {options.map(( item: any ) => (
                                <Option key={item.key} value={item.value}>
                                    {item.key}
                                </Option>
                            ))}
                        </Select>
                    )}
                </Form.Item>
            </div>
            <div className="expiration-date">
                <Form.Item label='Data de expiração'>
                    {getFieldDecorator('expirationDate', {
                        rules: [
                            { required: true, message: 'Campo obrigatório' },
                            { validator: (rule: any, value: any, callback: any) => {
                                if (!value) {
                                    callback();
                                    return;
                                }

                                const now = moment();

                                let isInThePast = false;
                                try {
                                    isInThePast = moment(value).isBefore(now);
                                } catch (e) {
                                    callback('Data inválida');
                                }

                                if (isInThePast) {
                                    callback('A data não pode estar no passado');
                                } else {
                                    callback();
                                }
                            }},
                        ],
                    })(
                        <DataPicker expirationDate={currentEdit.expirationDate} onChangeDate={onChangeDate}/>
                    )}
                </Form.Item>
            </div>
        </div>
    );
};

export default SelectBanner;
